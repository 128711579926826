import services from './services.js';
const keys = Object.keys(services);

window.onload = function () {



    /*  scroll to section */

    let anchorList = document.querySelectorAll('.nav__link');
    anchorList.forEach(link => {
        link.onclick = function (e) {
            e.preventDefault();
            let destination =
                document.querySelector(this.hash);
            destination.scrollIntoView({
                behavior: 'smooth'
            });
        }
    });

    /* slick sllider */

    $('.team__block').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: false,


        responsive: [
            {
                breakpoint: 1139,
                settings: {
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 4000,
                },

            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 4000,
                }
            },
            {
                breakpoint: 539,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 4000,
                }
            },

        ]

    });


    /* popup */

    let popup = document.querySelector('#popup__call_craftsman');
    let blackBG = document.querySelector('.black-bg');
    let closePopup = document.querySelector('.close-btn');

    let btnCraftsman = document.querySelectorAll('.call_craftsman');

    closePopup.onclick = function () {
        console.log('hello');
        popup.classList.remove('show');
        blackBG.classList.remove('show');
    };


    for (let i = 0; i < btnCraftsman.length; i++) {
        btnCraftsman[i].onclick = function () {
            blackBG.classList.add('show');
            popup.classList.add('show');
        }
    };


    blackBG.onclick = function (e) {
        if (e.target.className != 'popup') {
            popup.classList.remove('show');
            blackBG.classList.remove('show');
        };
    };





    /*  вкладки */

    let tabLi = document.querySelectorAll('.services__service__name');


    /*    let linkToPricelist = document.querySelectorAll('.service__link_to_category'); */

    let servicesBlock = document.querySelector('.services__block');
    let serviceContainer = document.createElement('div');
    serviceContainer.classList.add('services__pricelist__wrapper');
    serviceContainer.classList.add('tab_container');


    servicesBlock.appendChild(serviceContainer);


    /* let accordionTabName = document.createElement('div');
    accordionTabName.classList.add('tab__accordion__name'); */


    let openedCategory = document.createElement('h3');
    openedCategory.classList.add('service__name__chosen');


    /* в режиме вкладок */

    for (let i = 1; i <= tabLi.length; i++) {

        let currentTabLiAttr = 'tab' + i;

        let accordionTabName = document.createElement('div');
        accordionTabName.classList.add('tab__accordion__name');

        accordionTabName.setAttribute('id', currentTabLiAttr);
        accordionTabName.innerHTML = services[keys[i - 1]][0].categoryIcon + services[keys[i - 1]][0].categoryName;
        serviceContainer.appendChild(accordionTabName);


        let pricelist = document.createElement('div');
        pricelist.classList.add('services__pricelist');
        pricelist.classList.add('tab_content');



        
        pricelist.setAttribute('id', '_' + currentTabLiAttr);
        serviceContainer.appendChild(pricelist);


        let currentCategoryContent = services[keys[i - 1]];


        for (let k = 1; k < currentCategoryContent.length; k++) {
           

            let serviceItem = document.createElement('div');
            serviceItem.classList.add('service__item');


            pricelist.appendChild(serviceItem);
            let priceBlock = document.createElement('div');
            priceBlock.classList.add('service__name__price');
            serviceItem.appendChild(priceBlock);


            let itemName = document.createElement('h3');
            itemName.classList.add('service__item__name');
            itemName.innerText = currentCategoryContent[k].serviceName;
            priceBlock.appendChild(itemName);

            let price = document.createElement('div');
            price.classList.add('service__item__price');
            price.innerText = currentCategoryContent[k].servicePrice;
            priceBlock.appendChild(price);


            let descr = document.createElement('div');
            descr.classList.add('service__item__description');
            descr.innerText = currentCategoryContent[k].serviceDetail;
            serviceItem.appendChild(descr);

        };


    };


    for (let i = 0; i < tabLi.length; i++) {

        tabLi[i].onclick = function () {
            for (let j = 0; j < tabLi.length; j++) {
                if (tabLi[j].classList.contains('--active_li')) {
                    let prevLi = tabLi[j];
                    let prevTabLiAttr = prevLi.getAttribute("rel");

                    prevLi.classList.remove('--active_li');
                    
                    let prevTab = document.getElementById('_' + prevTabLiAttr);
                    prevTab.classList.remove('active_tab');
                    
                    
                    let prevAccord = document.getElementById(prevTabLiAttr);
                    prevAccord.classList.remove('active_tab');
                    

                };
            };
            this.classList.add('--active_li');
            let currentLi = this.innerText;
            
            let currentTabLiAttr = this.getAttribute("rel");
            
            let currentTab = document.getElementById('_' + currentTabLiAttr);
            currentTab.classList.add('active_tab');
            
            let currentAccord = document.getElementById(currentTabLiAttr);
            currentAccord.classList.add('active_tab');
            
            
            openedCategory.innerText = currentLi;
            serviceContainer.prepend(openedCategory);
            
            
        };
    };


    tabLi[0].click();


    /* в режиме аккордеона */
    
    let accTab = document.querySelectorAll('.tab__accordion__name');

    for (let i = 0; i < accTab.length; i++) {
        accTab[i].onclick = function () {
            this.classList.toggle('active_tab');
            
            let currentTabLiAttr = this.getAttribute("id");
            let currentTab = document.getElementById('_' + currentTabLiAttr);
            currentTab.classList.toggle('active_tab');
            
            
            
            };
           
      
    }
    

};